import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const VerticalStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 2rem;
  h2 {
    overflow: hidden;
  }
`
const BoxWraper = styled.div`
  padding: 0.75rem;
`
const TextWraper = styled.div`
  padding: 0 0 1rem 0;
  height: 3rem;
`

const PostBox = ({ post }) => {
  const title = post.frontmatter.title || post.fields.slug
  return (
    <BoxWraper>
      <Link to={'/oferta' + post.fields.slug}>
        <div key={post.fields.slug} className="box has-background-primary">
          <div className="content is-small">
            <VerticalStyled>
              <h2 className="title is-size-5">{title}</h2>
            </VerticalStyled>
            <TextWraper>
              <p dangerouslySetInnerHTML={{ __html: post.excerpt }} />
            </TextWraper>
          </div>
        </div>
      </Link>
    </BoxWraper>
  )
}

export default PostBox
