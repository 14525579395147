import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import Container from '../components/Container'
import About from '../components/About'
import SerwicePostList from '../components/SerwicePostList'
import Banner from '../components/Banner'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = 'Lancet strona główna'
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        slides={data.heroSlides}
        images={data.heroImages}
      >
        <SEO
          title={siteTitle}
          keywords={[
            `Lancet`,
            `ostrzenie`,
            `drzwi`,
            `Kraków`,
            `master-key`,
            `awaryjne`,
            `otwieranie`,
            `dorabianie`,
            `klucze`,
            `wkładki`,
            `samochodowe`,
            `wymiana`,
            `baterii`,
            `zamki`,
            `skrzynki`,
            `gałki`,
            `gerda`,
            `ABUS`,
            `LOB`,
            `MCM`,
            `brelok`,
          ]}
        />
        <section className="section has-background-primary">
          <Container>
            <SerwicePostList posts={posts} />
          </Container>
        </section>
        <section className="section is-small">
          <Container>
            <div className="notification has-text-dark">
              <h1>Nowa lokalizacja </h1>
              <br />
              <p>
                Od Lutego zapraszamy do nowo otwartego punktu przy{' '}
                <strong>ul. Kalwaryjskiej 82</strong>, gdzie również będziemy
                ostrzyć, dorabiać klucze, sprzedawać cążki i inne produkty
                kosmetyczne, art. metalowe, itp.
              </p>
            </div>
          </Container>
        </section>
        <Banner />
        <section className="section is-medium">
          <About />
        </section>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt(pruneLength: 80)
          fields {
            slug
          }
          frontmatter {
            icon
            title
          }
        }
      }
    }

    heroSlides: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/hero/" } }
      sort: { fields: frontmatter___order }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            subtitle
          }
        }
      }
    }

    heroImages: allFile(
      filter: { sourceInstanceName: { eq: "hero" }, name: { eq: "slide" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 60, maxWidth: 1940) {
              ...GatsbyImageSharpFluid
            }
          }
          relativeDirectory
        }
      }
    }
  }
`
