import React from 'react'

import Slider from './Slider'
import PostBox from './PostBox'

const PostSliderOptions = {
  dots: true,
  arrows: false,
  infinite: true,
  // autoplay: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 840,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const SerwicePostList = ({ posts }) => {
  const servicesBoxes = posts.map(({ node }) => (
    <PostBox key={node.fields.slug} post={node} />
  ))

  return <Slider options={PostSliderOptions}>{servicesBoxes}</Slider>
}

export default SerwicePostList
