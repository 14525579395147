import React from 'react'
import Container from './Container'

const About = () => {
  return (
    <Container>
      <div className="content">
        <h1 className="title is-size-2">
          Lancet to firma rodzinna istniejaca, od 1949&nbsp;roku
        </h1>
        <h2 className="is-size-4">
          Założona przez F. Jackiewicza na ul. Karmelickiej 12 od roku 1997 na
          ul.&nbsp;Karmelickiej&nbsp;17 pod nazwą F.H.U. Lancet.
        </h2>

        <ul>
          <li>
            Firma zajmuje się dorabianiem kluczy w bardzo szerokim zakresie (w
            tym również nietypowych)
          </li>
          <li>Ostrzeniem</li>
          <li>Dorabianiem kluczy od samochodów</li>
          <li>Wymianą obudów kluczyków samochodowych</li>
        </ul>
      </div>
    </Container>
  )
}

export default About
