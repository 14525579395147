import React from 'react'
import styled from 'styled-components'
import Icon from '../utils/Icon'
import * as contact from '../components/Contact/index'

const StyledIconWraper = styled(Icon)`
  height: 4.5rem;
  width: 4.5rem;

  padding-right: 1rem;
  fill: ${props => props.inputColor};
`

const StyledHelpSection = styled.div.attrs({
  className: 'has-background-dark is-flex',
})`
  flex: 1;
  justify-content: flex-end;
  padding: 2.5rem 1.5rem;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`
const StyledAlarmSection = styled.div.attrs({
  className: 'has-background-primary',
})`
  flex: 1;
  padding: 2.5rem 1.5rem;
`
const StyledBannerItem = styled.div.attrs({
  className: 'is-text-white',
})`
  flex-basis: 480px;
`

const Banner = () => {
  return (
    <div className="is-flex-tablet">
      <StyledHelpSection>
        <StyledBannerItem>
          <div className="media">
            <div className="media-left">
              <StyledIconWraper name="tarcza" inputColor="white" />
            </div>
            <div className="media-content">
              <div className="content is-medium">
                <h1 className="title has-text-white"> Służymy pomocą</h1>
                <ul className="has-text-white">
                  <li>Awaryjne otwieranie drzwi</li>
                  <li>Montaż i wymiana zamków</li>
                  <li>Klucze</li>
                </ul>
              </div>
            </div>
          </div>
        </StyledBannerItem>
      </StyledHelpSection>
      <StyledAlarmSection>
        <StyledBannerItem>
          <div className="media">
            <div className="media-left">
              <StyledIconWraper name="alarm" inputColor="#2e2529" />
            </div>
            <div className="media-content">
              <div className="content is-medium">
                <h1 className="title has-text-dark">Telefon alarmowy</h1>
                <p>
                  Dostępność 24h
                  <br />
                  +48 {contact.mobilePhoneAlarmNumber}
                </p>
              </div>
            </div>
          </div>
        </StyledBannerItem>
      </StyledAlarmSection>
    </div>
  )
}

export default Banner
